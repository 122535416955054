import React, { Suspense, lazy } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useSelectAuth } from "storeProvider/selectors";

// lazy loading
const AuthApp = lazy(() => import("./AuthApp"));
const UnAuthApp = lazy(() => import("./UnAuthApp"));

const App = () => {
  const auth = useSelectAuth();

  // console.log("Auth State", auth);

  return (
    <Suspense fallback={<p style={{ padding: 24 }}>Chargement d'App' ...</p>}>
      <CssBaseline />
      {auth.loggedIn === true ? <AuthApp /> : <UnAuthApp />}
    </Suspense>
  );
};

export default App;
