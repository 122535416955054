import React from "react";
import axios from "axios";
import dayjs from "dayjs";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { SnackbarProvider } from "notistack";
import StoreProvider from "storeProvider";
import App from "components/app/App";

// import date locale
import "dayjs/locale/fr";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(isSameOrBefore);

// add withCredentials to axios defaults
axios.defaults.withCredentials = true;

const queryClient = new QueryClient();

const Root = () => (
  <Router basename={process.env.REACT_APP_PUBLIC_PATH}>
    <SnackbarProvider>
      <QueryClientProvider client={queryClient}>
        <StoreProvider>
          <App />
        </StoreProvider>
      </QueryClientProvider>
    </SnackbarProvider>
  </Router>
);

export default Root;
