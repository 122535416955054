import React, { createContext, useContext, useReducer } from "react";
import { rootReducer } from "storeProvider/reducer";

const { id = null, username = "", name = "", roles = [] } =
  JSON.parse(localStorage.getItem("DRZUser")) || {};
const loggedIn = Boolean(id && username !== "" && roles.length > 0);
const authState = {
  loggedIn: loggedIn,
  user: loggedIn ? { id, username, name, roles } : null,
};

// initial context state
export const initState = {
  auth: authState,
};

// create the context
export const AppContext = createContext({});

// export the store hook
export const useStore = () => useContext(AppContext);

// store provider component
const StoreProvider = ({ children }) => {
  const [store, dispatch] = useReducer(rootReducer, initState);

  // memoizes the contextValue so only rerenders if store or dispatch change
  // const contextValue = useMemo(() => ({store, dispatch}), [store, dispatch]);

  return <AppContext.Provider value={{ store, dispatch }}>{children}</AppContext.Provider>;
};

export default StoreProvider;
