import { useStore } from "storeProvider";

// accepts a selector callback and aa array of params
export const useSelector = (selector, params = []) => {
  const { store } = useStore();
  return selector(store, ...params); // params is an array
};

export const getAuth = ({ auth }) => auth;

export const useSelectAuth = () => useSelector(getAuth);
