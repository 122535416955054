import { useStore } from "storeProvider";

// accepts a selector callback and aa array of params
export const useDispatch = () => {
  const { dispatch } = useStore();
  return dispatch; // params is an array
};

// Actions
export const LOGIN = "login";
export const loginAction = (user) => ({
  type: LOGIN,
  user,
});

export const LOGOUT = "LOGOUT";
export const logoutAction = () => ({
  type: LOGOUT,
});
