import { LOGIN, LOGOUT } from "storeProvider/actions";

// Reducers
export const rootReducer = (state, action) => {
  switch (action.type) {
    case LOGIN: {
      const { user } = action;
      // console.log("Action:LOGIN", user);
      localStorage.setItem(
        "DRZUser",
        JSON.stringify({
          id: user.id,
          username: user.username,
          name: user.name,
          roles: user.roles,
        })
      );

      return {
        ...state,
        auth: {
          loggedIn: true,
          user,
        },
      };
    }

    case LOGOUT: {
      localStorage.removeItem("DRZUser");
      localStorage.clear();

      return {
        ...state,
        auth: {
          loggedIn: false,
          user: null,
        },
      };
    }

    default:
      return state;
  }
};
